//@font-face {
//    font-family: 'Druk Wide Cyr';
//    src: url('../fonts/druke/Druk Wide Medium.otf');
//    font-weight: normal;
//    font-style: normal;
//}

//@font-face {
//    font-family: 'Druk Wide Cyr';
//    src: url('../fonts/druke/DrukCyr-Heavy.eot');
//    src: local('Druk Cyr Heavy'), local('DrukCyr-Heavy'),
//    url('../fonts/druke/DrukCyr-Heavy.eot?#iefix') format('embedded-opentype'),
//    url('../fonts/druke/DrukCyr-Heavy.woff2') format('woff2'),
//    url('../fonts/druke/DrukCyr-Heavy.woff') format('woff'),
//    url('../fonts/druke/DrukCyr-Heavy.ttf') format('truetype');
//    font-weight: 900;
//    font-style: normal;
//}

//@font-face {
//    font-family: 'Druk Wide Cyr';
//    src: url('../fonts/druke/DrukCyr-HeavyItalic.eot');
//    src: local('Druk Cyr Heavy Italic'), local('DrukCyr-HeavyItalic'),
//    url('../fonts/druke/DrukCyr-HeavyItalic.eot?#iefix') format('embedded-opentype'),
//    url('../fonts/druke/DrukCyr-HeavyItalic.woff2') format('woff2'),
//    url('../fonts/druke/DrukCyr-HeavyItalic.woff') format('woff'),
//    url('../fonts/druke/DrukCyr-HeavyItalic.ttf') format('truetype');
//    font-weight: 900;
//    font-style: italic;
//}
//
//@font-face {
//    font-family: 'Druk Wide Cyr';
//    src: url('../fonts/druke/DrukCyr-MediumItalic.eot');
//    src: local('Druk Cyr Medium Italic'), local('DrukCyr-MediumItalic'),
//    url('../fonts/druke/DrukCyr-MediumItalic.eot?#iefix') format('embedded-opentype'),
//    url('../fonts/druke/DrukCyr-MediumItalic.woff2') format('woff2'),
//    url('../fonts/druke/DrukCyr-MediumItalic.woff') format('woff'),
//    url('../fonts/druke/DrukCyr-MediumItalic.ttf') format('truetype');
//    font-weight: 500;
//    font-style: italic;
//}
//
//@font-face {
//    font-family: 'Druk Wide Cyr';
//    src: url('../fonts/druke/DrukCyr-SuperItalic.eot');
//    src: local('Druk Cyr Super Italic'), local('DrukCyr-SuperItalic'),
//    url('../fonts/druke/DrukCyr-SuperItalic.eot?#iefix') format('embedded-opentype'),
//    url('../fonts/druke/DrukCyr-SuperItalic.woff2') format('woff2'),
//    url('../fonts/druke/DrukCyr-SuperItalic.woff') format('woff'),
//    url('../fonts/druke/DrukCyr-SuperItalic.ttf') format('truetype');
//    font-weight: normal;
//    font-style: italic;
//}
//
//@font-face {
//    font-family: 'Druk Wide Cyr';
//    src: url('../fonts/druke/DrukCyr-Super.eot');
//    src: local('Druk Cyr Super'), local('DrukCyr-Super'),
//    url('../fonts/druke/DrukCyr-Super.eot?#iefix') format('embedded-opentype'),
//    url('../fonts/druke/DrukCyr-Super.woff2') format('woff2'),
//    url('../fonts/druke/DrukCyr-Super.woff') format('woff'),
//    url('../fonts/druke/DrukCyr-Super.ttf') format('truetype');
//    font-weight: normal;
//    font-style: normal;
//}
@font-face {
    font-family: 'Druk Wide Cyr';
    src: url('../fonts/druke/Druk Wide Cyr Bold.otf');
    src: local('Druk Cyr Bold Italic'), local('DrukCyr-BoldItalic'),
    url('../fonts/druke/Druk Wide Cyr Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: Montserrat;
    src: url("../fonts/montserrat/Montserrat-Regular.ttf");
    font-weight: 500;
    font-style: normal;
}
